.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 9999; /* Higher z-index value to keep the modal in the foreground */
}

.modal-content {
  position: absolute;
  /* top: 20; */
  /* margin:20; */
  width: 90%;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  background-color: #fff; /* Modal content background color */
  padding: 20px;
  
  z-index: 10000; /* Higher z-index value than the overlay to keep the content on top */
}
.modal-scrollable-content {
  height: 200px; /* Set the desired height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
}

img{
  height: 200;
  width: 200;
  margin: 10;
  
}