.icon-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 8px;
  }

.date-pill {
  background-color: #f27006e3;
  font-weight:bold;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 8px;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 10px;
}

  @media (max-width: 1169px) {
    .icon-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
      }
  }