.outer{
    display: flex;
    font-family: serif;}

.left{
    margin: 5px;
    display: flex;
    align-items: center;

}

.image{
    width:100px;
    height:100px;
    border-radius: 5px;
}

.right{
    margin: 1rem;
    flex-grow: 1;
}

.heading{
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 1.2rem;
}

.body{
    font-size: 1.2rem;
    margin-top: 0.6rem;
    font-weight: 600;
}

.relation{
    line-height: 1.2rem;
    font-size: 0.9rem;
}

.footer{
    /* font-weight: 600;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid #e1e5ee; */
    border: none;
    border-top: 1px solid #e1e5ee;
    background-color: transparent;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bold;
    color: inherit;
    width: 100%;
    padding-top: 1rem;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.1rem;

}