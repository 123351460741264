.obituary {

    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .obituary-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .obituary-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .obituary-description {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .obituary-contact {
    margin-top: 20px;
  }
  
  .obituary-contact-person {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .obituary-address {
    font-size: 14px;
    color: #888888;
    margin-bottom: 0;
  }

  @media (max-width: 1169px) {
   .obituary-name{
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
   }

   .card-preview{
    width: 100%;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 2px;
    /* margin-bottom: 10px; */
    border: 1px solid black;
   }

   .image-container{
    position: relative;
    text-align: center;
    color: white;
    
   }

   .text-over-image{
    position: absolute;
    background-color: #070707;
    opacity: 0.5;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    border: 1px solid black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   }

   .eye-image{
    width: 10px;
    height:10px;
   }
  }